import contact from "./lib/contact";
import rebox from "./lib/rebox";
import { toggleBelowFold, smoothScroll } from "./lib/scroll";

$(document).ready(function () {
    contact("#contact-form");
    rebox(".reference-thumbimage");
    toggleBelowFold();
    // scrollSpy('.header-nav-sub:visible', {offset: 0})
    smoothScroll('a[href^="#"]', { offset: 0 });

    $(document).scroll(function () {
        var scroll = $(this).scrollTop();
        if ($(window).width() >= 991 && scroll > 0) {
            $(".header").addClass("fixed");
        } else {
            $(".header").removeClass("fixed");
        }
    });
});

$(".gallery-slider .neos-contentcollection").slick({
    dots: false,
    arrows: true,
    infinite: true,
    speed: 600,
    cssEase: "linear",
    ease: "ease-in",
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: false,
    useTransform: true,
    variableWidth: true,
    pauseOnHover: true,
    responsive: [
        {
            breakpoint: 500,
            settings: {
                variableWidth: false,
            },
        },
    ],
});

$(document).ready(function () {
    $(".read-more").on("click", function () {
        $(this).prev().toggle();
        if ($(this).text() == "Mehr Referenzen ansehen") {
            $(this).text("Weniger Referenzen ansehen");
        } else {
            $(this).text("Mehr Referenzen ansehen");
        }
    });
});
